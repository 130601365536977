import { message } from 'antd'
import axios, { type AxiosError } from 'axios'

export interface ApiErrorData {
  message: string
}

const staticTokenconfig = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${String(process.env.REACT_APP_STATIC_TOKEN)}`,
  },
  timeout: 5000,
}

export const checkStatus = async (type: string, email: string) => {
  try {
    const response = axios.get(`/auth/link-status/${type}?email=${email}`, staticTokenconfig)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      console.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
  }
}

export const verifyEmail = async (type: string, payload: { email: string }) => {
  try {
    const response = axios.patch(`/auth/verify-signup/${type}`, payload, staticTokenconfig)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      console.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
    return undefined
  }
}

export const resetPassword = async (type: string, payload: { email: string; password: string }) => {
  try {
    const response = axios.patch(`/auth/reset-password/${type}`, payload, staticTokenconfig)
    return await response
  } catch (err: unknown) {
    const axiosError = err as AxiosError<ApiErrorData>
    if (axios.isAxiosError(axiosError)) {
      void message.error(axiosError?.response?.data?.message)
    } else {
      console.error('An unexpected error occurred', err)
    }
    return undefined
  }
}
