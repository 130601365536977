import React from 'react'
import { Spin } from 'antd'

const Loader = ({ isLoad }: { isLoad: boolean }) => {
  return (
    <>
      {isLoad && (
        <>
          <div className="loader-wrapper">
            <span className="ant-zoom">
              <Spin fullscreen size="large" />
            </span>
          </div>
        </>
      )}
    </>
  )
}

export default Loader
