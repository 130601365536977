import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Button, Form, message } from 'antd'
import type { Rule } from 'antd/es/form'
import LogoIcon from 'assets/svgs/LogoIcon'
import PasswordField from 'component/PasswordField'
import { checkStatus, resetPassword } from 'Services/Services'
import Loader from 'utils/Loader'

export interface IFieldData {
  name: string[]
  errors?: string[] | undefined
}

export const passwordValidations: Rule[] = [
  { required: true, message: 'Please input your password!' },
  ({ getFieldValue }) => ({
    async validator(_, value) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/

      if (value) {
        if (value.length < 8) {
          return await Promise.reject(new Error('Password minimum 8 characters long'))
        }
        if (!passwordRegex.test(value)) {
          return await Promise.reject(new Error('Include at least one number, one special character, one uppercase letter, and one lowercase letter.'))
        }
      }

      await Promise.resolve()
    },
  }),
]

const ResetPassword = () => {
  const [error, setError] = useState({
    password: '',
    conPassword: '',
  })
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)
  const location = useLocation()
  const [form] = Form.useForm()
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email') ?? ''
  const type = searchParams.get('type') ?? ''
  const navigate = useNavigate()

  useEffect(() => {
    if (type === '' || email === '') {
      setShouldRedirect(true)
    }
  }, [type, email])

  useEffect(() => {
    void handleCheckStatus()
  }, [])

  const onFinish = (values: { password: string }) => {
    const payload = {
      email,
      password: values?.password,
    }
    void handleResetPassword(payload)
  }

  const handleCheckStatus = async () => {
    setIsLoad(true)
    const response = await checkStatus(type, email)
    console.log(response)
    if (response?.data?.statusCode === 1) {
      setIsVerified(true)
    } else {
      navigate('/link-expiry')
    }
    setIsLoad(false)
  }

  const handleFieldsChange = (allFields: IFieldData[]) => {
    allFields.forEach((field: IFieldData) => {
      const fieldName = field.name[0]
      const fieldErrors = field.errors ?? ''
      setError((prev) => {
        return { ...prev, [fieldName]: fieldErrors[0] }
      })
    })
  }

  const handleResetPassword = async (data: { email: string; password: string }) => {
    setIsLoad(true)
    const response = await resetPassword(type, data)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      setTimeout(() => {
        if (type === 'admin') {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          window.location.href = `${process.env.REACT_APP_REDIRECTION_URL_MASTER}/login`
        } else if (type === 'facility') {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          window.location.href = `${process.env.REACT_APP_REDIRECTION_URL_FACILITY}/login`
        } else if (type === 'facility_user') {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          window.location.href = `${process.env.REACT_APP_REDIRECTION_URL_FACILITY}/facility-user-login`
        } else {
          navigate('/reset-success')
        }
      }, 1000)
    } else {
      form.resetFields()
      // void message.error(response?.data?.message)
    }
    setIsLoad(false)
  }

  const validateConfirmPassword = ({ getFieldValue }: { getFieldValue: (name: string) => any }) => ({
    async validator(_: any, value: any) {
      await new Promise<void>((resolve, reject) => {
        if (!value || getFieldValue('password') === value) {
          resolve() // Validation passed
        } else {
          reject(new Error('Confirm password does not match!'))
        }
      })
    },
  })

  return shouldRedirect ? (
    <Navigate to="/" />
  ) : (
    <div className="bg-neutral-50">
      <div className="bg-neutral-50 mx-auto max-w-400 w-full h-screen flex flex-col gap-10 justify-center items-center no-scroll">
        <div className="flex gap-6 flex-col items-center">
          <LogoIcon />
          <p className="mobile-heading-h2 font-medium">Reset Password</p>
        </div>

        <div className="w-full min-w-full">
          <Form layout="vertical" form={form} onFinish={onFinish} className=" min-w-full" requiredMark={false} onFieldsChange={handleFieldsChange}>
            <div className={`h-64 ${!error?.password ? 'mb-6' : ''}`}>
              <PasswordField
                name="password"
                rules={[
                  { required: true, message: 'Please input your new password!' },
                  {
                    validator: async (_: any, value: any) => {
                      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/

                      if (value) {
                        if (value.length < 8) {
                          throw new Error('Password must be at least 8 characters long')
                        }
                        if (!passwordRegex.test(value)) {
                          throw new Error('Password must include at least one number, one special character, one uppercase letter, and one lowercase letter.')
                        }
                      }
                    },
                  },
                ]}
                placeholder="New Password"
              />
            </div>
            {error?.password && <p className="error-container mb-6">{error.password}</p>}
            <div className={` h-64 ${!error?.conPassword ? 'mb-6' : ''}`}>
              <PasswordField name="conPassword" rules={[{ required: true, message: 'Please confirm your password' }, validateConfirmPassword]} placeholder="Confirm Password" />
            </div>
            {error?.conPassword && <p className="error-container mb-6">{error.conPassword}</p>}
            <Form.Item className="mt-6">
              <Button type="primary" htmlType="submit" className="common-button">
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Loader isLoad={isLoad} />
    </div>
  )
}

export default ResetPassword
