import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Logo from 'assets/svgs/Logo'
import { verifyEmail } from 'Services/Services'
import Loader from 'utils/Loader'

const Verification = () => {
  const [responseMessage, setResponseMessage] = useState()
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email') ?? ''
  const type = searchParams.get('type') ?? ''
  const navigate = useNavigate()

  useEffect(() => {
    if (type && email) {
      void handleVerifyEmail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, email])

  useEffect(() => {
    if (type === '' || email === '') {
      setShouldRedirect(true)
    }
  }, [type, email])

  const handleVerifyEmail = async () => {
    setIsLoad(true)
    const payload = {
      email,
    }
    const response = await verifyEmail(type, payload)
    if (response?.data?.statusCode === 1) {
      setResponseMessage(response?.data?.message)
      if (type === 'facility') {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        window.location.href = `${process.env.REACT_APP_REDIRECTION_URL_FACILITY}/welcome?id=${response?.data?.data?.id}`
      }
    } else {
      setResponseMessage(response?.data?.message)
      navigate('/link-expiry')
    }
    setIsLoad(false)
  }

  return shouldRedirect ? (
    <Navigate to="/" />
  ) : (
    <div className="main">
      <Logo />

      <h1 className="verify-message">{responseMessage}</h1>

      <Loader isLoad={isLoad} />
    </div>
  )
}

export default Verification
