import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LinkExpiry from 'pages'
import Home from 'pages/Home'
import ResetPassword from 'pages/ResetPassword'
import ResetSuccess from 'pages/ResetSuccess'
import Verification from 'pages/Verification'

const Approutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/verification" element={<Verification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/reset-success" element={<ResetSuccess />} />
      <Route path="/link-expiry" element={<LinkExpiry />} />

      {/* page not found */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default Approutes
