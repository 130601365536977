import { useRef, useState } from 'react'
import { Form, Input } from 'antd'
import PasswordInvisibleIcon from 'assets/svgs/PasswordInvisibleIcon'
import PasswordVisibleIcon from 'assets/svgs/PasswordVisibleIcon'

interface IPasswordField {
  placeholder: string
  value?: string
  rules?: any
  onChange?: (value: string, labelName: string) => void
  name: string
  inputClass?: string
}

const PasswordField = ({ name, rules, placeholder, inputClass }: IPasswordField) => {
  const [focused, setFocused] = useState(false)

  const [visible, setVisible] = useState(false)
  const inputRef: any = useRef<HTMLInputElement>(null)

  const toggleVisibility = () => {
    setVisible(!visible)
    if (inputRef.current) {
      const cursorPosition = inputRef.current.input.selectionStart
      setTimeout(() => {
        inputRef.current?.input?.setSelectionRange(cursorPosition, cursorPosition)
      }, 0)
    }
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(e.target.value !== '')
  }

  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <Form.Item name={name} rules={rules} className={`floating-label-password ${inputClass}`}>
      <div>
        <label className={`label-float ${focused ? 'actives' : ''}`}>{placeholder}</label>
        <Input
          size="small"
          ref={inputRef}
          variant="borderless"
          type={visible ? 'text' : 'password'}
          onFocus={handleFocus}
          onBlur={handleBlur}
          suffix={<span onClick={toggleVisibility}>{visible ? <PasswordVisibleIcon /> : <PasswordInvisibleIcon />}</span>}
        />
      </div>
    </Form.Item>
  )
}

export default PasswordField
