import React from 'react'
import { ConfigProvider } from 'antd'
import Approutes from 'routes/Approutes'

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#220EB1',
          fontFamily: 'Geologica, sans-serif',
        },
      }}
    >
      <Approutes />
    </ConfigProvider>
  )
}

export default App
