import React from 'react'
import Logo from 'assets/svgs/Logo'

const ResetSuccess = () => {
  return (
    <div className="main">
      <Logo />
      <h1 className="verify-message">Your Password Reset Successful</h1>
    </div>
  )
}

export default ResetSuccess
