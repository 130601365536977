import React from 'react'

const Logo = () => {
  return (
    <svg width="187" height="31" viewBox="0 0 187 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.7122 10.502H31.3797C27.9197 10.502 24.7697 11.8244 22.4072 13.9944C25.0522 16.4219 26.7097 19.9044 26.7097 23.7769C26.7097 21.1994 28.7997 19.1095 31.3772 19.1095H37.7097C38.3197 19.1095 38.8122 18.6169 38.8122 18.0069V11.6069C38.8122 10.9969 38.3197 10.5045 37.7097 10.5045L37.7122 10.502Z"
        fill="#6111B9"
      ></path>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M22.4075 13.9925C20.045 11.825 16.895 10.5 13.435 10.5H7.10249C6.49249 10.5 6 10.9925 6 11.6025V18.0025C6 18.6125 6.49249 19.105 7.10249 19.105H13.435C16.0125 19.105 18.1025 21.195 18.1025 23.7725C18.1025 19.9 19.76 16.4175 22.405 13.99L22.4075 13.9925Z"
          fill="#59D1F4"
        ></path>
      </g>
      <path
        d="M22.407 13.9922C19.762 16.4197 18.1045 19.9022 18.1045 23.7747V29.7772C18.1045 30.0822 18.227 30.3572 18.427 30.5572C18.477 30.6072 18.532 30.6522 18.5895 30.6922C18.7645 30.8122 18.977 30.8797 19.207 30.8797H25.607C25.8345 30.8797 26.047 30.8097 26.2245 30.6922C26.282 30.6522 26.337 30.6072 26.387 30.5572C26.587 30.3572 26.7095 30.0822 26.7095 29.7772V23.7747C26.7095 19.9022 25.052 16.4197 22.407 13.9922Z"
        fill="#220EB1"
      ></path>
      <path
        d="M22.4078 0C20.0203 0 17.8628 1.1825 16.3153 3.09C16.1778 3.26 16.1478 3.51499 16.2378 3.72749L17.8353 7.06C17.9228 7.2675 18.1078 7.385 18.2978 7.36C19.6478 7.18 21.0203 7.08501 22.4103 7.08501C23.8003 7.08501 25.1728 7.18 26.5228 7.36C26.7128 7.385 26.8978 7.2675 26.9853 7.06L28.5828 3.72749C28.6728 3.51749 28.6403 3.26 28.5053 3.09C26.9578 1.185 24.8003 0 22.4103 0H22.4078Z"
        fill="#220EB1"
      ></path>
      <path
        d="M44.7672 24.3248V11.1873H48.9921V12.5773C49.4821 11.9598 50.0621 11.5073 50.7296 11.2248C51.3996 10.9423 52.1021 10.7998 52.8421 10.7998C54.0796 10.7998 55.0971 11.0423 55.8946 11.5273C56.6921 12.0123 57.2846 12.6398 57.6646 13.4073C58.0471 14.1748 58.2372 14.9898 58.2372 15.8473V24.3223H54.0121V17.1748C54.0121 16.3848 53.8046 15.7623 53.3871 15.3073C52.9696 14.8523 52.3596 14.6248 51.5521 14.6248C51.0371 14.6248 50.5871 14.7398 50.2071 14.9673C49.8246 15.1948 49.5271 15.5098 49.3121 15.9148C49.0971 16.3173 48.9896 16.7873 48.9896 17.3198V24.3273H44.7646L44.7672 24.3248Z"
        fill="#6111B9"
      ></path>
      <path
        d="M65.7741 24.7096C64.5816 24.7096 63.5941 24.4746 62.8116 24.0071C62.0291 23.5396 61.4491 22.8921 61.0666 22.0621C60.6841 21.2346 60.4941 20.2696 60.4941 19.1696V11.1846H64.7191V18.5396C64.7191 19.2596 64.9091 19.8321 65.2916 20.2521C65.6741 20.6721 66.2291 20.8821 66.9591 20.8821C67.4316 20.8821 67.8466 20.7771 68.2016 20.5671C68.5566 20.3571 68.8316 20.0671 69.0266 19.6971C69.2216 19.3271 69.3166 18.8996 69.3166 18.4096V11.1846H73.5416V24.3221H69.8966V22.9321C69.4766 23.4046 68.9441 23.8196 68.2991 24.1746C67.6541 24.5296 66.8141 24.7096 65.7741 24.7096Z"
        fill="#6111B9"
      ></path>
      <path
        d="M76.0523 24.3244V11.1869H80.2773V12.7194C80.6723 12.0419 81.1948 11.5469 81.8498 11.2369C82.5023 10.9269 83.2098 10.7744 83.9748 10.7744C84.2673 10.7744 84.5298 10.7919 84.7598 10.8269C84.9923 10.8619 85.2198 10.8994 85.4423 10.9419L84.9398 15.2944C84.6473 15.1819 84.3398 15.0969 84.0123 15.0369C83.6848 14.9769 83.3598 14.9469 83.0323 14.9469C82.2348 14.9469 81.5748 15.1744 81.0548 15.6294C80.5348 16.0844 80.2748 16.7544 80.2748 17.6394V24.3244H76.0498H76.0523Z"
        fill="#6111B9"
      ></path>
      <path
        d="M91.4051 24.6849C90.1851 24.6849 89.0726 24.5349 88.0626 24.2349C87.0526 23.9349 86.1851 23.5099 85.4551 22.9599L86.7676 20.0624C87.4801 20.5174 88.2451 20.8749 89.0601 21.1374C89.8751 21.3999 90.6476 21.5299 91.3776 21.5299C92.0476 21.5299 92.5576 21.4374 92.9101 21.2524C93.2626 21.0674 93.4376 20.7999 93.4376 20.4474C93.4376 20.1374 93.2776 19.9099 92.9551 19.7649C92.6326 19.6199 92.0051 19.4599 91.0676 19.2874C89.2976 18.9874 88.0051 18.4924 87.1851 17.8049C86.3651 17.1174 85.9551 16.1699 85.9551 14.9599C85.9551 14.1024 86.1901 13.3499 86.6626 12.7049C87.1351 12.0599 87.7901 11.5599 88.6276 11.2049C89.4651 10.8474 90.4276 10.6699 91.5201 10.6699C92.6126 10.6699 93.5926 10.8124 94.5401 11.0949C95.4876 11.3774 96.3026 11.7724 96.9801 12.2799L95.7051 15.1899C95.3526 14.9149 94.9476 14.6749 94.4876 14.4699C94.0276 14.2649 93.5526 14.1049 93.0651 13.9924C92.5776 13.8799 92.1001 13.8249 91.6351 13.8249C91.0601 13.8249 90.5926 13.9124 90.2376 14.0899C89.8801 14.2674 89.7026 14.5074 89.7026 14.8174C89.7026 15.1274 89.8576 15.3724 90.1651 15.5249C90.4751 15.6799 91.0926 15.8549 92.0201 16.0524C93.8926 16.4299 95.2176 16.9399 96.0001 17.5799C96.7801 18.2199 97.1726 19.1149 97.1726 20.2649C97.1726 21.1574 96.9301 21.9349 96.4451 22.5974C95.9601 23.2574 95.2851 23.7724 94.4226 24.1374C93.5601 24.5024 92.5526 24.6849 91.4026 24.6849H91.4051Z"
        fill="#6111B9"
      ></path>
      <path
        d="M105.147 24.7098C103.705 24.7098 102.437 24.4198 101.342 23.8398C100.247 23.2598 99.3973 22.4498 98.7923 21.4048C98.1873 20.3623 97.8848 19.1398 97.8848 17.7398C97.8848 16.7273 98.0598 15.7948 98.4123 14.9448C98.7648 14.0948 99.2623 13.3598 99.9073 12.7423C100.552 12.1248 101.31 11.6448 102.18 11.3073C103.052 10.9673 104.01 10.7998 105.057 10.7998C106.207 10.7998 107.235 11.0048 108.135 11.4123C109.037 11.8198 109.782 12.3873 110.37 13.1123C110.957 13.8373 111.362 14.6923 111.587 15.6748C111.81 16.6573 111.82 17.7248 111.612 18.8748H101.747C101.755 19.3898 101.91 19.8398 102.21 20.2273C102.51 20.6148 102.922 20.9173 103.447 21.1348C103.97 21.3548 104.58 21.4623 105.277 21.4623C106.05 21.4623 106.785 21.3623 107.48 21.1598C108.175 20.9573 108.84 20.6473 109.475 20.2273L110.802 23.0873C110.4 23.3973 109.877 23.6723 109.237 23.9173C108.597 24.1623 107.92 24.3548 107.202 24.4973C106.485 24.6398 105.8 24.7098 105.147 24.7098ZM102.005 16.0298H107.595C107.577 15.3698 107.332 14.8398 106.86 14.4448C106.387 14.0498 105.747 13.8523 104.94 13.8523C104.132 13.8523 103.447 14.0498 102.912 14.4448C102.375 14.8398 102.072 15.3673 102.005 16.0298Z"
        fill="#6111B9"
      ></path>
      <path
        d="M118.155 24.6849C116.935 24.6849 115.823 24.5349 114.813 24.2349C113.803 23.9349 112.935 23.5099 112.205 22.9599L113.518 20.0624C114.23 20.5174 114.995 20.8749 115.81 21.1374C116.625 21.3999 117.398 21.5299 118.128 21.5299C118.798 21.5299 119.308 21.4374 119.66 21.2524C120.013 21.0674 120.188 20.7999 120.188 20.4474C120.188 20.1374 120.028 19.9099 119.705 19.7649C119.383 19.6199 118.755 19.4599 117.818 19.2874C116.048 18.9874 114.755 18.4924 113.935 17.8049C113.115 17.1174 112.705 16.1699 112.705 14.9599C112.705 14.1024 112.94 13.3499 113.413 12.7049C113.885 12.0599 114.54 11.5599 115.378 11.2049C116.215 10.8474 117.178 10.6699 118.268 10.6699C119.358 10.6699 120.34 10.8124 121.288 11.0949C122.235 11.3774 123.05 11.7724 123.728 12.2799L122.453 15.1899C122.1 14.9149 121.695 14.6749 121.235 14.4699C120.775 14.2649 120.303 14.1049 119.813 13.9924C119.323 13.8799 118.848 13.8249 118.383 13.8249C117.808 13.8249 117.34 13.9124 116.985 14.0899C116.63 14.2674 116.45 14.5074 116.45 14.8174C116.45 15.1274 116.605 15.3724 116.913 15.5249C117.22 15.6774 117.84 15.8549 118.768 16.0524C120.64 16.4299 121.965 16.9399 122.748 17.5799C123.53 18.2199 123.92 19.1149 123.92 20.2649C123.92 21.1574 123.678 21.9349 123.193 22.5974C122.708 23.2574 122.033 23.7724 121.17 24.1374C120.308 24.5024 119.3 24.6849 118.15 24.6849H118.155Z"
        fill="#6111B9"
      ></path>
      <path
        d="M125.895 24.325V11.575H128.727V13.1975C129.26 12.485 129.877 11.9725 130.582 11.6575C131.287 11.345 132.017 11.1875 132.772 11.1875C133.94 11.1875 134.905 11.42 135.67 11.8825C136.435 12.345 137.002 12.9425 137.377 13.6725C137.75 14.4025 137.937 15.155 137.937 15.9275V24.325H135.105V16.815C135.105 15.895 134.832 15.165 134.287 14.62C133.742 14.075 132.985 13.8025 132.015 13.8025C131.38 13.8025 130.815 13.9425 130.32 14.22C129.825 14.5 129.437 14.8925 129.155 15.3975C128.872 15.905 128.73 16.4875 128.73 17.15V24.325H125.897H125.895Z"
        fill="#59D1F4"
      ></path>
      <path
        d="M147.037 24.71C145.697 24.71 144.507 24.4025 143.462 23.79C142.417 23.1775 141.602 22.355 141.01 21.33C140.417 20.305 140.122 19.1775 140.122 17.95C140.122 17.04 140.292 16.18 140.63 15.3675C140.97 14.555 141.447 13.8375 142.065 13.21C142.682 12.5825 143.415 12.09 144.26 11.7275C145.105 11.3675 146.03 11.1875 147.035 11.1875C148.365 11.1875 149.552 11.495 150.595 12.1075C151.637 12.7225 152.455 13.5425 153.047 14.5675C153.64 15.595 153.935 16.72 153.935 17.9475C153.935 18.85 153.765 19.7075 153.427 20.5225C153.087 21.3375 152.61 22.06 151.992 22.6875C151.375 23.315 150.645 23.8075 149.802 24.1675C148.96 24.5275 148.037 24.7075 147.032 24.7075L147.037 24.71ZM147.037 22.1975C147.817 22.1975 148.517 22.0225 149.13 21.675C149.745 21.3275 150.227 20.835 150.58 20.2C150.932 19.565 151.107 18.8125 151.107 17.945C151.107 17.0775 150.932 16.325 150.58 15.685C150.227 15.045 149.745 14.5525 149.13 14.21C148.515 13.8675 147.815 13.695 147.025 13.695C146.235 13.695 145.532 13.8675 144.92 14.21C144.305 14.5525 143.825 15.045 143.477 15.685C143.13 16.325 142.955 17.0775 142.955 17.945C142.955 18.8125 143.13 19.5625 143.482 20.2C143.835 20.835 144.317 21.3275 144.932 21.675C145.547 22.0225 146.247 22.1975 147.037 22.1975Z"
        fill="#59D1F4"
      ></path>
      <path
        d="M159.197 24.3242L155.192 11.5742H158.012L160.742 21.0542L163.73 11.5742H166.5L169.295 21.0542L172.077 11.5742H174.77L170.765 24.3242H167.842L165.035 15.1792L162.125 24.3242H159.202H159.197Z"
        fill="#59D1F4"
      ></path>
      <path
        d="M176.695 13.4799V11.8199H176.103V11.3799H177.78V11.8199H177.183V13.4799H176.695ZM178.118 13.4799V11.3799H178.583L179.325 12.5299L180.055 11.3799H180.518V13.4799H180.048L180.058 12.2124L179.483 13.0799H179.155L178.58 12.2124V13.4799H178.118Z"
        fill="#59D1F4"
      ></path>
    </svg>
  )
}

export default Logo
