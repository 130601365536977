import React from 'react'
import Logo from 'assets/svgs/Logo'

const Home = () => {
  return (
    <div className="main">
      <Logo />
    </div>
  )
}

export default Home
